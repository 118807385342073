import { Box, Typography, IconButton, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import authApi from "../../api";
import { useNavigate } from "react-router-dom";

const UpcomingDepositsPlanDetails = () => {
  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  const { planId, userId, name, phoneNumber } = useParams();
  const [upcomingDepositsViewMore, setUpcomingDepositsViewMore] = useState([]);
  const [savingPlanTransactions, setSavingPlanTransactions] = useState([]);
  useEffect(() => {
    fetchUpcomingDepositsViewMore(planId, userId);
  }, [planId, userId]);

  const fetchUpcomingDepositsViewMore = async (userId) => {
    const apiUrl = `${base_url}/api/v1/ccapi/upcoming-deposits?${planId}`;

    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate("/");
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setUpcomingDepositsViewMore(data.results);
    } catch (error) {
      console.error("Error fetching user transactions", error);
    }
  };

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "investor_id",
      headerName: "User ID",
    },
    {
      field: "plan_id",
      headerName: "Plan ID",
    },
  ];
  return (
    <>
      <Box m="20px">
        <Box>
          <Header
            title="Upcoming Deposit List"
            subtitle={`Total Number of Upcoming Deposits: `}
          />
        </Box>

        <Box m="40px 0 0 0">
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"
              >
                More Details
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[400],
                color: `#fff`,
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[400],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rowHeight={65}
              rows={upcomingDepositsViewMore}
              columns={columns}
              hideFooterPagination={true}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default UpcomingDepositsPlanDetails;
