import { Box, Typography, IconButton, Button } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import authApi from "../../api";
import { useNavigate } from 'react-router-dom';

const ReferralJewellerStoreDetails = () => {
    
     //for the dashboard theme
  const theme = useTheme();
  // to change the color on theme selected
  const colors = tokens(theme.palette.mode);

  //the base url for the dashboard
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  //pagination
  const { userId, phoneNumber, name } = useParams();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // to show the count
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);



  // const [usersList, setUsersList] = useState([]);

  //users list + filtered user lists
  const [withdrawalList, setwithdrawalList] = useState([]);



 // calling popup
 const handleRowClick = (params) => {
  setSelectedRow(params.row);
  setIsModalOpen(true);
};

const handleCloseModal = () => {
  setIsModalOpen(false);
};
  useEffect(() => {
    fetchWithdrawalList(userId);
  }, [userId]);

  const fetchWithdrawalList = async (userId) => {
    //pageNumber is our custom pagination we are using from server side, try and implement it in the default datagrid
    setLoading(true);

    //searchQuery helps us filter the user data
    const apiUrl = `${base_url}/api/v1/jewellerdashboard/referral/${userId}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setData(data.id);
      const jewellerStoreData =data.jeweller_store;
    //   console.log(jewellerStoreData)
      const rowsArray = [
        {
            "referral_id": data.id,
          "store_name": jewellerStoreData.store_name,
          "id": jewellerStoreData.id,
          "address": jewellerStoreData.address,
          "location": jewellerStoreData.location,
          "contact_number": jewellerStoreData.contact_number,
        }
      ]
      
      setwithdrawalList(rowsArray);
        // console.log(jewellerStoreData);
        // console.log(data.results);

     
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
        field: "referral_id",
        headerName: "Referral ID",
        
      },
        {
          field:"id",
          headerName: "Store ID",
          minWidth: 150
        },
        {
            field:"store_name",
            headerName: "Store Name",
            minWidth: 200
          },
          {
            field:"contact_number",
            headerName: "Contact Number",
            minWidth: 150
          },
          {
            field:"address",
            headerName: "Address",
            minWidth: 200
          },
          {
            field:"location",
            headerName: "Location",
            minWidth: 250
          },
  ];
  return (
    <>
    <Box m="20px">
      {/* Header */}
      <Box>
        <Header
          title="Jeweller Store Details"
        />
      </Box>
      {/* DataTable */}

      <Box m="40px 0 0 0" height="75vh">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="/dashboard"
            >
              Dashboard
            </Link>
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="/referrallist"
            >
              Referred Users
            </Link>
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="#"
            >
              Jeweller Store Details
            </Link>
          </Breadcrumbs>
        </div>
        <Box
          height="75vh"
          width="100%"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.grey[400],
              color: `#fff`,
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.grey[400],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid
            rowHeight={75}
        rows={withdrawalList}
        columns={columns}
        hideFooterPagination = {true}
        onCellClick={handleRowClick}

          />
        </Box>
        <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
  <DialogTitle>User Details</DialogTitle>
  <DialogContent>
    {selectedRow && (
      <div>
        <table>
            <tbody>
                <tr>
                    <th>
                        Referral ID
                    </th>
                    <td>
                        {selectedRow.referral_id}
                    </td>
                </tr>
                <tr>
                    <th>
                        Store ID
                    </th>
                    <td>
                    {selectedRow.id}
                    </td>
                </tr>
                <tr>
                    <th>
                        Store Name
                    </th>
                    <td>
                    {selectedRow.store_name}
                    </td>
                </tr>
                <tr>
                    <th>
                        Contact Number
                    </th>
                    <td>
                    {selectedRow.contact_number}
                    </td>
                </tr>
                <tr>
                    <th>
                        Address
                    </th>
                    <td>
                    {selectedRow.address}
                    </td>
                </tr>
                <tr>
                    <th>
                        Location
                    </th>
                    <td>
                    <a style={{textDecoration:"none", color:"#fff"}} href={selectedRow.location}>{selectedRow.location}</a>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    )}
  </DialogContent>
  <DialogActions>
    <Button variant="contained" onClick={handleCloseModal} color="warning">
      Close
    </Button>
  </DialogActions>
</Dialog>
        
      </Box>
    </Box>
  </>
  );
}

export default ReferralJewellerStoreDetails;