import axios from "axios";
const BASE_URL = "https://proapi.getplus.in"
const authToken = async () =>{
    const auth = await localStorage.getItem("auth");
    const {token} = !!auth?JSON.parse(auth):{};
    return token 
}
const post = async (url, token, data = {}, _config = {}) => {
    const config = { ..._config };
    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }
    return axios
      .post(url, data, { ...config })
      .then((res) => {
        if (res.status) {
          const { data, status } = res;
          return { data, status };
        } else {
          return {
            status: 200,
            data: res,
          };
        }
      })
      .catch(({ response }) => {
        return response;
      });
  };
  
  const put = async (url, token, data = {}, _config = {}) => {
    const config = { ..._config };
    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }
    return axios
      .put(url, data, { ...config })
      .then((res) => {
        if (res.status) {
          const { data, status } = res;
          return { data, status };
        } else {
          return {
            status: 200,
            data: res,
          };
        }
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          return response.data;
        }
        return response;
      });
  };
  
  const get = async (url, token, _config = {}) => {
    const config = { ..._config };
    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }
    return await axios.get(url, { ...config }).then((res) => res.data);
  };
  const authApi = {
    logIn: async (params) => {
        try {
          const res = await post(`${BASE_URL}/api/v1/verification-otp/`, null, params);
          if (res?.status === 400) {
            // console.log(res.status)
            return {
              error: true,
              message: [res.data.message],
            };
          } else if (res?.statusCode === 401) {
            return {
              error: true,
              message: ["Incorrect Email Address or password."],
            };
          }else if (res?.statusCode === 403) {
            return {
              error: true,
              message: ["Access Denied! Please Reach Out To Customer Care"],
            };
          }
            return res.data;
          } catch (e) {
          // console.log(e)
          return {
            error: true,
            message: e,
          };
        }
      },

      getOtp: async (params) =>{
        return await post (`${BASE_URL}/api/v1/login/`,null,params)
      },

      // getDownloadLogs: async (params) =>{
      //   return await post (`${BASE_URL}/api/v1/ccapi/agents/data-download-logs`,await authToken(),params)
      // },


      // sendReminder: async (params) =>{
      //   return await post (`${BASE_URL}/api/v1/ccapi/gold-coin/send-cart-pickup-reminder`,await authToken(),params)
      // },

      // totalUserCount:  async () =>{
      //   return await get (`${BASE_URL}/api/v1/ccapi/user-count`,await authToken())
      // },
// check from here.............. 

totalMatured:  async () =>{
  return await get (`${BASE_URL}/api/v1/jewellerdashboard/savings-plan/total-matured-plans`,await authToken())
},

totalUpcomingInstallment: async () => {
  return await get (`${BASE_URL}/api/v1/jewellerdashboard/upcoming-installments/total-upcoming-installments`, await authToken())
},
      totalSavings:  async () =>{
        return await get (`${BASE_URL}/api/v1/jewellerdashboard/savings-plan/total-jeweller-aum`,await authToken())
      },

      totalGoldCoinOrders:  async () =>{
        return await get (`${BASE_URL}/api/v1/jewellerdashboard/gold-coin/total-gold-coin`,await authToken())
      },

      // totalContests:  async () =>{
      //   return await get (`${BASE_URL}/api/v1/ccapi/contest/contest-count`,await authToken())
      // },

      totalmissedInstallment:  async () =>{
        return await get (`${BASE_URL}/api/v1/jewellerdashboard/missed-installments/total-missed-installments`,await authToken())
      },

      totalupcomingMaturity:  async () =>{
        return await get (`${BASE_URL}/api/v1/jewellerdashboard/savings-plan/total-upcoming-maturity-plans`,await authToken())
      },


      totalReferrals:  async () =>{
        return await get (`${BASE_URL}/api/v1/jewellerdashboard/referral/total-referrals`,await authToken())
      },
      totalApointment:  async () =>{
        return await get (`${BASE_URL}/api/v1/jewellerdashboard/appointments/total-appointments`,await authToken())
      },
      totalStoreView:  async () =>{
        return await get (`${BASE_URL}/api/v1/jewellerdashboard/store-views/total-stores-views`,await authToken())
      },
      JewellerProfile:  async () =>{
        return await get (`${BASE_URL}/api/v1/jewellerdashboard/jeweller-access-profile`,await authToken())
      },
      withdrawalList: async () => {
        return await get (`${BASE_URL}/api/v1/jewellerdashboard/withdrawal`,await authToken())
      },
      // visitList: async () => {
      //   return await get (`${BASE_URL}/api/v1/jewellerdashboard/store-views`,await authToken ())
      // },
      // userListdata: async (pageNumber, pageSize, searchQuery) => {
      //   return await get (`${BASE_URL}/api/v1/jewellerdashboard/jeweller-stores?pageNumber=${pageNumber}&pageSize=${pageSize}&searchQuery=${encodeURIComponent(searchQuery)}`,await authToken())
      // }
      // totalUserCount:  async () =>{
      //   return await get (`${BASE_URL}/api/v1/ccapi/user-count`,await authToken())
      // },
    //   payment:  async (params) =>{
    //     return await post (`${BASE_URL}/api/v1/periodic_plan_seamless/`,await authToken(),params)
    //   },
    
  
      
  }

  export default authApi