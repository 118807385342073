import * as React from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Link as RouterLink } from "react-router-dom";
import Header from "../../components/Header";
import Button from "@mui/material/Button";
import { getAccessToken } from "../../utils/authUtils";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import authApi from "../../api";
import ReferralsList from "../referrals/ReferralsList";
import { BarChart } from '@mui/x-charts/BarChart';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useLocation } from 'react-router-dom';


const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Base API URL
  const base_url = "https://proapi.getplus.in";
  // Need to make the access token Dynamic so we dont have to manually change it everytime
  // const access_token = getAccessToken();

  // State to set the data fetched from the API on UI
  const [totalSaving, settotalSaving] = useState([]);
  const [totaGoldCoinOrders, setTotalGoldCoinOrders] = useState([]);
  const [totalWithdrawals, setTotalWithdrawals] = useState([]);
  const [totalUpcomingInstallments, setTotalUpcomingInstallments] = useState(
    []
  );
  const [missedInstallment, setMissedInstallment] = useState([]);
  const [totalStores, setTotalStores] = useState([]);
  const [maturity, setMaturity] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [matured, setmatured] = useState([]);
  const [apointment, setApointment] = useState([]);
  const [visit, setVisit] = useState([]);
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const name = state && state.name ? state.name : 'Guest';
  // console.log(location.state);  

  // Callback will be called on every re-render. The useEffect() hook is used to run a side-effect, every time the component’s state changes. It takes a callback function as its first argument. This callback function will get called when the state of the component is changed.

  //This is to fetch the total number of the user count
  useEffect(() => {
    fetchTotalStores();
    fetchtotalSavings();
    fetchTotalGoldCoinOrders();
    fetchTotalUpcomingInstallments();
    fetchTotalmissedInstallment();
    fetchTotalUpcomingMaturityPlans();
    fetchtotalReferrals();
    fetchtotalMaturedPlans();
    fetchtotalApointment();
    fetchtotalStoreView();
  }, []);
  //This is to fetch the total number of the user count
  // dynamic api call function

  // with Jeweller Stores| Total Jeweller Stores | List Jeweller store details

  const fetchTotalStores = async () => {
    const apiUrl = `${base_url}/api/v1/jewellerdashboard/jeweller-stores/total-stores`;
    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        console.error("Access token not found");

        return;
      }
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setTotalStores(data.total);
    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  };

  //This is to fetch the total SIP Money
  const fetchtotalSavings = async () => {
    try {
      const data = await authApi.totalSavings();
      settotalSaving(data.total);
    } catch (error) {
      navigate("/");
      console.log("Error fetching data:", error);
    }
  };
  
  const fetchTotalGoldCoinOrders = async () => {
    try {
      const data = await authApi.totalGoldCoinOrders();
      setTotalGoldCoinOrders(data.total);
    } catch (error) {
      // console.log("Error fetching data:", error);
      navigate("/");
    }
  };

  const fetchTotalUpcomingInstallments = async () => {
    try {
      const data = await authApi.totalUpcomingInstallment();
      setTotalUpcomingInstallments(data.total);
    } catch (error) {
      navigate("/");
      console.log("Error fetching data:", error);
    }
  };
  const fetchTotalmissedInstallment = async () => {
    try {
      const data = await authApi.totalmissedInstallment();
      setMissedInstallment(data.total);
    } catch (error) {
      navigate("/");
      console.log("Error fetching data:", error);
    }
  };

  const fetchTotalUpcomingMaturityPlans = async () => {
    try {
      const data = await authApi.totalupcomingMaturity();
      setMaturity(data.total);
    } catch (error) {
      navigate("/");
      console.log("Error fetching data:", error);
    }
  };
  const fetchtotalMaturedPlans = async () => {
    try {
      const data = await authApi.totalMatured();
      setmatured(data.total);
    } catch (error) {
      navigate("/");
      console.log("Error fetching data:", error);
    }
  };
  const fetchtotalApointment = async () => {
    try {
      const data = await authApi.totalApointment();
      setApointment(data.total);
    } catch (error) {
      navigate("/");
      console.log("Error fetching data:", error);
    }
  };

  const fetchtotalStoreView = async () => {
    try {
      const data = await authApi.totalStoreView();
      setVisit(data.total);
    } catch (error) {
      navigate("/");
      console.log("Error fetching data:", error);
    }
  };
  const fetchtotalReferrals = async () => {
    try {
      const data = await authApi.totalReferrals();
      setReferrals(data.total);
    } catch (error) {
      navigate("/");
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchTotalWithdrawals();
  }, []);

  const fetchTotalWithdrawals = async () => {
    const apiUrl = `${base_url}/api/v1/jewellerdashboard/withdrawal/total-withdrawal-requests`;
    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");

        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setTotalWithdrawals(data.total);
    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  };

  return (
    <>
      <Box margin="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Dashboard" subtitle="Welcome to your dashboard" />
        </Box>

        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to={"/dashboard"}
            >
              Dashboard
            </Link>
          </Breadcrumbs>
        </div>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {/* Total Referrals */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink
                to={"/referrallist"}
                style={{ textDecoration: "none" }}
              >
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {/* Total count of downloaded Users Displayed here */}
                    <h2 style={{ textAlign: "center", margin:"0" }}>{referrals}</h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>Total Referrals </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Customers
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* Total Savings */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink
                to={"/totalsavingplanslist"}
                style={{ textDecoration: "none" }}
              >
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center", margin:"0" }}>INR {totalSaving} </h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>Total&nbsp;Savings</h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Saving Plans
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* UPCOMING INSTALLMENTS */}

            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink
                to={"/upcominginstallments"}
                style={{ textDecoration: "none" }}
              >
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" , margin:"0"}}>
                      {totalUpcomingInstallments}{" "}
                    </h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>
                      Upcoming&nbsp;Installments
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Users Upcoming Installments
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* Missed Deposits */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink
                to={"/misseddeposits"}
                style={{ textDecoration: "none" }}
              >
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center", margin:"0" }}>
                      {missedInstallment}{" "}
                    </h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>
                      Missed&nbsp;Installment
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Users Missed Installment
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* Upcoming Maturity */}

            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink
                to={"/upcommingmaturity"}
                style={{ textDecoration: "none" }}
              >
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center", margin:"0" }}>{maturity}</h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>
                      Upcoming&nbsp;Maturity
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Upcoming Maturities
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* matured cards */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink
                to={"/maturedplans"}
                style={{ textDecoration: "none" }}
              >
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center", margin:"0" }}>{matured} </h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>Total&nbsp;Matured</h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Total matured
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* Total Gold Coin Orders */}
            {/* Total Gold coins|  */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink
                to={"/goldcoinorders"}
                style={{ textDecoration: "none" }}
              >
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center", margin:"0" }}>
                      {totaGoldCoinOrders} 
                    </h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>
                      Gold&nbsp;Coin&nbsp;Orders
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Gold Coin Orders
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* Withdrawal Requests */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/withdrawal"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center", margin:"0" }}>{totalWithdrawals}</h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>
                      Total&nbsp;Withdrawals
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Withdrawals
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* Total Appointment */}

            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink
                to={"/apointmrntlist"}
                style={{ textDecoration: "none" }}
              >
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center", margin:"0" }}>{apointment}</h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>
                      Total&nbsp;Apointment
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Total Apointment
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* Total Stores */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink
                to={"/jewellerstorelist"}
                style={{ textDecoration: "none" }}
              >
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center", margin:"0" }}>{totalStores} </h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>Total&nbsp;Stores</h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Stores
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* Profile Vists */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink
                to={"/profilevisitlist"}
                style={{ textDecoration: "none" }}
              >
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "8em",
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center", margin:"0" }}>{visit}</h2>
                    <h3 style={{ textAlign: "center", margin:"0" }}>
                      Total&nbsp;Profile&nbsp;Visits
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" color="warning">
                        View Profile Visit Details
                      </Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      <Box display={"flex"} justifyContent={"space-around"} alignItems={"end"}>

      <Box width={"100%"}>
      <Grid 
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid sx={{margin:"40px 0" }} item lg={8} xs={12} sm={12} md={12}>
        <ReferralsList />
        </Grid>
        <Grid sx={{margin:"40px 0" }} item lg={4} xs={12} sm={12} md={12}>
        <Box >
      
      <Box display={"flex"} justifyContent={"center"} alignItems={"end"} width={"100%"}>
        <Box marginTop={"50px"}>
        <h2 style={{textAlign:"center"}}>
          Referral Progress
        </h2>
        <Box display={"flex"} justifyContent={"center"} alignItems={"end"} width={"100%"}>
      <BarChart
      series={[
        { data: [3, 4, 1, 6, 5], stack: 'A', label: 'Series A1' },
        { data: [4, 3, 1, 5, 8], stack: 'A', label: 'Series A2' },
        { data: [4, 2, 5, 4, 1], stack: 'B', label: 'Series B1' },
        { data: [2, 8, 1, 3, 1], stack: 'B', label: 'Series B2' },
        { data: [10, 6, 5, 8, 9], label: 'Series C1' },
      ]}
      width={500}
      height={600}
    />  
    </Box>
    </Box>
    </Box>
    </Box>
        </Grid> 
      </Grid>
    </Box>
     
    </Box>
      </Box>
    </>
    
  );
};

export default Dashboard;