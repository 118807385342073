import { Box, Typography, IconButton, Button } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReminderButton from "./ReminderButton";
// import SearchBar from "material-ui-search-bar";
// import { getAccessToken } from "../../tokenService";
import { getAccessToken } from "../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const GoldCoinOrders = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  //pagination
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);

  // to show the count
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);


  //pagination buttons
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);

  const [goldCoinOrderedUserList, setGoldCoinOrderedUserList] = useState([]);

   //to filter the user list
   const [searchInput, setSearchInput] = useState("");

  // const [pickupReminder, setPickupReminder] = useState([]);
  const [count, setCount] = useState([]);
  const [openDialogReminder, setOpenDialogReminder] = useState(false);
  const [selectedItemIdReminder, setSelectedItemIdReminder] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDialogOtp, setOpenDialogOtp] = useState(false);
  const [selectedItemIdOtp, setSelectedItemIdOtp] = useState(null);

  const handleOpenDialogReminder = (itemId) => {
    setOpenDialogReminder(true);
    setSelectedItemIdReminder(itemId);
  };

  const handleOpenDialogOtp = (itemId) => {
    setOpenDialogOtp(true);
    setSelectedItemIdOtp(itemId);
  };

  const handleCloseDialogReminder = () => {
    setOpenDialogReminder(false);
    setSelectedItemIdReminder(null);
  };
  const handleCloseDialogOtp = () => {
    setOpenDialogOtp(false);
    setSelectedItemIdOtp(null);
  };
 
  //gets called on the search icon/button
  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchGoldCoinOrderedUserList(1, searchInput);
  };

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  const accessToken = getAccessToken();

  useEffect(() => {
    fetchGoldCoinOrderedUserList(page, pageSize, searchInput);
  }, [page, searchInput]);

  const fetchGoldCoinOrderedUserList = async (pageNumber, pageSize, searchQuery) => {
    setLoading(true);

    const apiUrl = `${base_url}/api/v1/jewellerdashboard/gold-coin?page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;
    try {
      
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setCount(data.count);
      setData(data);

      //this helps in the row count for both cutom pagination and datagrid row count
      setTotalRows(data.count);

      //this is to navigate to the next page using the custom navigation button
      setNextPage(data.next);

      //this is to navigate to the previous page using the custom navigation button
      setPreviousPage(data.previous);


      setGoldCoinOrderedUserList(data.results);

      // }
    } catch (error) {
      console.log("Error fetching data:", error);
    }finally{
      setLoading(false);
    }
  };

   //next page navigation in pagination
   const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };

   //previous page navigation in pagination
   const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);
  
      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }
  
      setPage(pageNumber);
    }
  };


  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const sendPickupReminder = (cartId) => {
    const apiUrl = `${base_url}/api/v1/ccapi/gold-coin/send-cart-pickup-reminder`;
    const requestBody = JSON.stringify({ cart_id: cartId });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: requestBody,
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (response.ok) {
          // console.log(response);
          return response.json();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((data) => {
        // console.log(data.message);
        // Handle the success response or update UI as needed
        window.alert(`Reminder sent for Cart ID: ${cartId}`);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle the error
      });
  };

  const sendOtpToJeweller = (cartId) => {
    const apiUrl = `${base_url}/api/v1/ccapi/gold-coin/send-cart-pickup-otp-jeweller`;
    const requestBody = JSON.stringify({ cart_id: cartId });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: requestBody,
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (response.ok) {
          // console.log(response);
          return response.json();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((data) => {
        // console.log(data.message);

        window.alert(`Reminder sent for Cart ID: ${cartId}`);

        // Handle the success response or update UI as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle the error
      });
  };
  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

   

    return buttons;
  };
  const handleSendReminder = () => {
    // Add your logic to send the reminder here using selectedItemIdReminder
    sendPickupReminder(selectedItemIdReminder);
  
    // Close the dialog
    handleCloseDialogReminder();
  };

  const handleSendOtp = () => {
    // Add your logic to send the reminder here using selectedItemIdReminder
    sendOtpToJeweller(selectedItemIdOtp);
  
    // Close the dialog
    handleCloseDialogOtp();
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };
  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
      
    },
    {
      field: "id",
      headerName: "Cart ID",
    },
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params) => params.row.investor.name,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      valueGetter: (params) => params.row.investor.phone_number,
    },
    {
      field: "purity",
      headerName: "Purity",
     
    },
    {
      field: "coin_weight",
      headerName: "Weight (grams)",
      
    },
    {
      field: "coin_price",
      headerName: "Coin Price",
     
    },
    {
      field: "coin_price_with_taxes",
      headerName: "Coin Price (Tax incl)",
    },
    {
      field: "paid_on",
      headerName: "Paid Date",
      renderCell: (params) => {
        const timestamp = params.row.paid_on;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
    },
    {
      field: "cart_otp",
      headerName: "Cart OTP",
    },

    {
      field: "order_status",
      headerName: "Order Status",
      minWidth: 150,
    },
    {
      field: "pickup_date",
      headerName: "Pickup Date",
      renderCell: (params) => {
        const timestamp = params.row.pickup_date;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
      minWidth: 100,
    },
    {
      field: "is_ready_to_pickup",
      headerName: "Ready To Pick Up",
    },
    {
      field: "is_picked_up",
      headerName: "Delivered",
    },
    {
      field: "jewellerStoreDetails",
      headerName: "Jeweller Details",
      renderCell: ({ ...params }) => (
        <RouterLink
          style={{ textDecoration: "none" }}
          to={`/goldCoinJewellerStoreDetails/${params.row.investor.id}/${params.row.id}`}
        >
          <Button variant="outlined" color="success">
            Jeweller Details
          </Button>
        </RouterLink>
      ),
      minWidth: 200,
    },
  ];

  return (
    <>
      <Box m="20px">
        <Box>
        <Header
          title={`Gold Coin Orders`}
          subtitle={`Total Orders: ${count}`}
        />
        </Box>
         {/* SEARCH BAR */}
         <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        <Box m="40px 0 0 0">
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"
              >
                Gold Coin Orders
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[400],
                color: `#fff`,
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[400],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              sx={{
                "& .MuiDataGrid-cellContent": {
                  whiteSpace: "normal",
                  overflow: "visible",
                  wordWrap: "break-word",
                },
              }}
              rowHeight={60}
              rows={goldCoinOrderedUserList}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination = {true}
              onRowClick={handleRowClick}
            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>Coin Order Details</DialogTitle>
        <DialogContent>
          {selectedRow && (
            <Box>
              <table>
                <tr>
                  <th>
                    Card ID
                  </th>
                  <td>
                    {selectedRow.id}
                  </td>
                </tr>
                <tr>
                  <th>
                    Name
                  </th>
                  <td>
                    {selectedRow.investor.name}
                  </td>
                </tr>
                <tr>
                  <th>
                    Phone Number
                  </th>
                  <td>
                    {selectedRow.investor.phone_number}
                  </td>
                </tr>
                <tr>
                  <th>
                    Gold Purity
                  </th>
                  <td>
                    {selectedRow.purity}
                  </td>
                </tr>
                <tr>
                  <th>
                    Coin Weight
                  </th>
                  <td>
                    {selectedRow.coin_weight}
                  </td>
                </tr>
                <tr>
                  <th>
                    Coin Price
                  </th>
                  <td>
                    {selectedRow.coin_price}
                  </td>
                </tr>
                <tr>
                  <th>
                    Coin Price (Tax incl)
                  </th>
                  <td>
                    {selectedRow.coin_price_with_taxes}
                  </td>
                </tr>
                <tr>
                  <th>
                    Coin Price (Tax incl)
                  </th>
                  <td>
                    {selectedRow.coin_price_with_taxes}
                  </td>
                </tr>
                <tr>
                  <th>
                    Paid On
                  </th>
                  <td>
                    {selectedRow.paid_on}
                  </td>
                </tr>
                <tr>
                  <th>
                    Cart OTP
                  </th>
                  <td>
                    {selectedRow.cart_otp}
                  </td>
                </tr>
                <tr>
                  <th>
                    Order Status
                  </th>
                  <td>
                    {selectedRow.order_status}
                  </td>
                </tr>
                <tr>
                  <th>
                    Delivery Date
                  </th>
                  <td>
                    {selectedRow.pickup_date?selectedRow.pickup_date:"No Date Yet"}
                  </td>
                </tr>
                <tr>
                  <th>
                    Ready To Deliver
                  </th>
                  <td>
                    {selectedRow.is_ready_to_pickup? "YES":"NO"}
                  </td>
                </tr>
                <tr>
                  <th>
                   Delivered
                  </th>
                  <td>
                    {selectedRow.is_picked_up? "YES":"NO"}
                  
                  </td>
                </tr>
              </table>
            </Box>
            
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained" color="warning">
            Close
          </Button>
        </DialogActions>
      </Dialog>
           {/* Pagination Buttons */}
           <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GoldCoinOrders;
