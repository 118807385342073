import { Box, Typography, IconButton, Button } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import authApi from "../../api";
import { useNavigate } from 'react-router-dom';


const Withdrawal = () => {

     //for the dashboard theme
  const theme = useTheme();
  // to change the color on theme selected
  const colors = tokens(theme.palette.mode);

  //the base url for the dashboard
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  //pagination
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // to show the count
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  //pagination buttons
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);

  // const [usersList, setUsersList] = useState([]);

  //users list + filtered user lists
  const [withdrawalList, setwithdrawalList] = useState([]);

  //to filter the user list
  const [searchInput, setSearchInput] = useState("");

   //gets called on the search icon/button
   const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchWithdrawalList(1, searchInput);
  };

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };
 // calling popup
 const handleRowClick = (params) => {
  setSelectedRow(params.row);
  setIsModalOpen(true);
};

const handleCloseModal = () => {
  setIsModalOpen(false);
};
  useEffect(() => {
    fetchWithdrawalList(page, pageSize, searchInput);
  }, [page, searchInput]);


  // const fetchWithdrawalList = async () => {
  //   try {
  //     const data = await authApi.withdrawalList();
  //     setData(data);
  //     setTotalRows(data.count);
  //     setNextPage(data.next);
  //     setPreviousPage(data.previous);
  //     setwithdrawalList(data.results);
  //   } catch (error) {
  //       console.log("Error fetching data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  // };

  const fetchWithdrawalList = async (pageNumber, pageSize, searchQuery) => {
    //pageNumber is our custom pagination we are using from server side, try and implement it in the default datagrid
    setLoading(true);

    //searchQuery helps us filter the user data
    const apiUrl = `${base_url}/api/v1/jewellerdashboard/withdrawal?page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      //this is data state to fetch the total count of users
      setData(data);

      //this helps in the row count for both cutom pagination and datagrid row count
      setTotalRows(data.count);

      //this is to navigate to the next page using the custom navigation button
      setNextPage(data.next);

      //this is to navigate to the previous page using the custom navigation button
      setPreviousPage(data.previous);

      //this stores all the data of the users we need and displays it in the table
      setwithdrawalList(data.results);
      //   console.log(data);
      //   console.log(data.results);

     
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  //next page navigation in pagination
  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };

  //previous page navigation in pagination
  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);
  
      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }
  
      setPage(pageNumber);
    }
  };

  // const pageNumbers = [];
  // for (let i = 1; i <= Math.ceil(totalRows / pageSize); i++) {
  //   pageNumbers.push(i);
  // }

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => {
        const allRowIds = params.api ? params.api.getAllRowIds() : [];
        return allRowIds.indexOf(params.id) + 1;
      },
    },
    {
      field: "id",
      headerName: "Withdrawal ID",
    },
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params) => params.row.investor.name,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email ID",
      valueGetter: (params) => params.row.investor.email,
      minWidth: 200,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      valueGetter: (params) => params.row.investor.phone_number,
      minWidth: 80,
    },
    {
      field: "amount",
      headerName: "Withdrawal Amount",
    },
      {
        field: "saving_plan_name",
        headerName: "Plan Name",
      },
      {
        field: "tx_id",
        headerName: "TX ID",
      },
      {
        field: "utr",
        headerName: "UTR",
        valueGetter: (params) => params.row.utr?params.row.utr: "No UTR",
      },
      {
        field: "is_withdrawal_done",
        headerName: "Withdrawal Completed",
        valueGetter: (params) => params.row.is_withdrawal_done?"Yes":"No",
        minWidth: 100,
      },
      {
        field: "User Withdrawal Detail",
        headerName: "Withdrawal Details",
        renderCell: ({ ...params }) => (
          <RouterLink
            style={{ textDecoration: "none" }}
            to={`/userWithdrawalDetails/${params.row.id}/${params.row.phone_number}/${
              params.row.name ? params.row.name : "No Name"
            }`}
          >
            <Button variant="outlined" color="success">
              View Withdrawal Details
            </Button>
          </RouterLink>
        ),
        minWidth: 250,
      },
  ];
  return (
    <>
    <Box m="20px">
      {/* Header */}
      <Box>
        <Header
          title="Withdrawl List"
          // subtitle={`Total Number of Withdrawal Request: ${data.count}`}
        />
      </Box>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase
          sx={{ ml: 2, flex: 1 }}
          placeholder="Search"
          value={searchInput}
          onChange={handleSearchInputChange}
        />
        <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </Box>
      {/* DataTable */}

      <Box m="40px 0 0 0" height="75vh">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="/dashboard"
            >
              Dashboard
            </Link>
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="#"
            >
              Withdrawals
            </Link>
          </Breadcrumbs>
        </div>
        <Box
          height="75vh"
          width="100%"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.grey[400],
              color: `#fff`,
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.grey[400],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid
            rowHeight={60}
            rows={withdrawalList}
            columns={columns}
            loading={loading}
            pagination
            page={page}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            rowCount={totalRows}
            hideFooterPagination = {true}
            onCellClick={handleRowClick}

          />
        </Box>
        <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
  <DialogTitle>Upcoming Installment- User Details</DialogTitle>
  <DialogContent>
    {selectedRow && (
      <div>
        <table>
          <tbody>
            {columns.map((column) => (
              <tr key={column.field}>
                <th>{column.headerName}</th>
                <td>
                  {column.renderCell
                    ? column.renderCell({ row: selectedRow })
                    : column.valueGetter
                    ? column.valueGetter({ row: selectedRow })
                    : selectedRow[column.field]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </DialogContent>
  <DialogActions>
    <Button variant="contained" onClick={handleCloseModal} color="warning">
      Close
    </Button>
  </DialogActions>
</Dialog>
        {/* Pagination Buttons */}
        <Box
          display={"flex"}
          m={"10px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {generatePageButtons()}
        </Box>
      </Box>
    </Box>
  </>
  );
}

export default Withdrawal;