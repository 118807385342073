import { Box, Typography, Button } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { getAccessToken } from "../../utils/authUtils";

const GoldCoinJewellerStoreDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

    const {userId, cartId} = useParams();
    const [jewellerStoreDetails, setJewellerStoreDetails] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const base_url = "https://proapi.getplus.in"
    const navigate = useNavigate();

 // calling popup
 const handleRowClick = (params) => {
  setSelectedRow(params.row);
  setIsModalOpen(true);
};

const handleCloseModal = () => {
  setIsModalOpen(false);
};
    useEffect(() => {
        fetchJewellerStoreDetails(cartId);
    }, [cartId]);

    const fetchJewellerStoreDetails = async (cartId) => {
        const apiUrl = `${base_url}/api/v1/jewellerdashboard/gold-coin/${cartId}`;
        try{
          const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate(`/`);
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
            const response = await fetch(apiUrl, 
                {
                method: "GET",
                headers,
              }
            );
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              const data = await response.json();
              const jewellerStoreData =data.jeweller_store;
            
            // console.log(jewellerStoreData);
           
            // console.log(data)
          
            const rowsArray = [
              {
                "store_name": jewellerStoreData.store_name,
                "id": jewellerStoreData.id,
                "address": jewellerStoreData.address,
                "location": jewellerStoreData.location,
                "contact_number": jewellerStoreData.contact_number,
                // "store_images": [],
                // "jeweller_logo": "https://staging-bucket-gp.s3.amazonaws.com/media/jewellers/Aadi%20Jeweller/logo/git_66ADE6b.png",
                "is_plus_verified": jewellerStoreData.is_plus_verified,
              }
            ]
            setJewellerStoreDetails(rowsArray);
            // console.log(rowsArray);
        } catch (error) {
            console.log("Error fetching data:", error);
          }
    }

    const columns = [
      {
        field: "Sl_No",
        headerName: "Sl No",
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
        
      },
        {
          field:"id",
          headerName: "Store ID",
          minWidth: 150
        },
        {
            field:"store_name",
            headerName: "Store Name",
            minWidth: 200
          },
          {
            field:"contact_number",
            headerName: "Contact Number",
            minWidth: 150
          },
          {
            field:"address",
            headerName: "Address",
            minWidth: 200
          },
          {
            field:"location",
            headerName: "Location",
            minWidth: 250
          },
          {
            field:"is_plus_verified",
            headerName: "Plus Verified",
            valueGetter: (params)=> {
              if(params.value){
                return "Yes";
              }else{
                return "No"
              }
                    },
            minWidth: 150
          },
      ]

    return(
        <>
        <Box m="20px">
        <Header
            title="Jeweller Store"
          />
        <Box m="40px 0 0 0" >
        

<div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit"  component={RouterLink} to="/dashboard">
          Dashboard
        </Link>
        <Link underline="hover" color="inherit"  component={RouterLink} to="/goldcoinorders">
          Gold Coin Orders
        </Link>
        <Link underline="hover" color="inherit"  component={RouterLink} to="#">
          Cart ID: {cartId}
        </Link>
      </Breadcrumbs>
    </div>
          <Box height="75vh" width="100%" 
        sx={{
          "& .MuiDataGrid-root":{
            border: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[400],
            color: `#fff`,
            borderBottom : "none"
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[400],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
        >
        <DataGrid 
        sx={{
          "& .MuiDataGrid-cellContent":{
            whiteSpace: 'normal', overflow: 'visible', wordWrap: 'break-word'
          },
        }}
        rowHeight={75}
        rows={jewellerStoreDetails}
        columns={columns}
        hideFooterPagination = {true}
        onCellClick={handleRowClick}

        />
         <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
  <DialogTitle>Jeweller Store Details</DialogTitle>
  <DialogContent>
          {selectedRow && (
            <Box>
              <table>
                <tbody>
                <tr>
                  <th>
                    Store ID
                  </th>
                  <td>
                    {selectedRow.id}
                  </td>
                </tr>
                <tr>
                  <th>
                    Store Name
                  </th>
                  <td>
                    {selectedRow.store_name}
                  </td>
                </tr>
                <tr>
                  <th>
                    Contact Number
                  </th>
                  <td>
                    {selectedRow.contact_number}
                  </td>
                </tr>
                <tr>
                  <th>
                    Address
                  </th>
                  <td>
                    {selectedRow.address}
                  </td>
                </tr>
                <tr>
                  <th>
                    Location
                  </th>
                  <td>
                   <a style={{textDecoration: 'none', color:'white'}} href={selectedRow.location}> {selectedRow.location} </a>
                  </td>
                </tr>
                <tr>
                  <th>
                    Plus Verified
                  </th>
                  <td>
                    {selectedRow.is_plus_verified?"YES":"NO"}
                  </td>
                </tr>
                </tbody>
              </table>
            </Box>
          )}
        </DialogContent>
  <DialogActions>
    <Button variant="contained" color="warning" onClick={handleCloseModal}>
      Close
    </Button>
  </DialogActions>
</Dialog>

        </Box>
        </Box>
        </Box>
        </>
    )
}

export default GoldCoinJewellerStoreDetails;