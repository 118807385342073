import * as React from 'react';
import { colorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
// import { Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import Sidebar from './pages/global/Sidebar';
import Topbar from './pages/global/Topbar';
import {styled} from '@mui/material';

import GoldCoinOrders from './pages/goldCoinOrders/GoldCoinOrders';
import GoldCoinJewellerStoreDetails from './pages/goldCoinOrders/GoldCoinJewellerStoreDetails';

import Login from './components/Login';
import { UserAuthProvider } from './context/AuthProvider';
import { useLocation } from 'react-router-dom';
import Withdrawal from './pages/withdrawal/Withdrawal';
import UserWithdrawalDetails from './pages/withdrawal/UserWithdrawalDetails';
import UpcomingDeposits from './pages/upcomingDeposits/UpcomingDeposits';
import UpcomingDepositsPlanDetails from './pages/upcomingDeposits/UpcomingDepositsPlanDetails';
import JewellerStoreList from './pages/jewellerStores/JewellerStoreList';
import MissedDeposits from './pages/missedDeposits/MissedDeposits';
import MissedInstallmentDetail from './pages/missedDeposits/MissedIntallmentDetail';
import ReferralsList from './pages/referrals/ReferralsList';
import ReferralJewellerStoreDetails from './pages/referrals/ReferralJewellerStoreDetails';
import UpcommingMaturity from './pages/upcomingMaturity/UpcomingMaturity';
import MaturedPlans from './pages/maturedPlans/MaturedPlans';
import ApointmrntList from './pages/apointment/ApointmrntList';
import ProfileVisitList from './pages/profileVisit/ProfileVisitList';
import ApointmentDetail from './pages/apointment/ApointmentDetail';
import ReferralSavingPlanList from './pages/referrals/ReferralSavingPlansList';
import TotalSavingPlans from './pages/totalSavings/TotalSavingPlans';
import SavingPlanMoreDetails from './pages/totalSavings/SavingPlanMoreDetails';
import ProfileVisitDetail from './pages/profileVisit/ProfileVisitDetail';


function App() {
  const location = useLocation();
  const shouldRenderSidebarAndTopbar = location.pathname !== '/';
  const RequireAuth = ({ type, children }) => {
    const isLoggedIn = !!localStorage.getItem(type);
    return isLoggedIn ? children : <Navigate to="/" replace />;
  };

  const [theme, colorMode] = useMode();



// Set the access token
// setAccessToken(token);
  return (
    <colorModeContext.Provider value={colorMode}>
      
      <ThemeProvider theme={theme}>
        
        <CssBaseline />
    <div className="app">
   
      <UserAuthProvider>
      <Routes>
      <Route path="/" element={ <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width:'100%' // This sets the container to take the full height of the viewport
  }}>
    <Login />
    </div>
    } />
      </Routes>
      </UserAuthProvider>
     
    {shouldRenderSidebarAndTopbar && (
        <>
          <Sidebar className="position" />
          <main className='content' style={{width:"100%"}}>
          <Topbar />
          <UserAuthProvider>
      <Routes>
           
        <Route path="/dashboard" element={<RequireAuth type="auth"> <Dashboard /></RequireAuth>}/>
        <Route path="/goldcoinorders" element={<RequireAuth type="auth"><GoldCoinOrders /></RequireAuth>} />
        <Route path='/goldCoinJewellerStoreDetails/:userId/:cartId' element={<RequireAuth type="auth"><GoldCoinJewellerStoreDetails /></RequireAuth>} />
        <Route path='/withdrawal' element={<RequireAuth type="auth"><Withdrawal /></RequireAuth>} />
        <Route path='/userWithdrawalDetails/:userId/:phoneNumber/:name?' element={<RequireAuth type="auth"><UserWithdrawalDetails /></RequireAuth>} />
        <Route path='/upcominginstallments' element={<RequireAuth type="auth"><UpcomingDeposits /></RequireAuth>} />
        <Route path='/misseddeposits' element={<RequireAuth type="auth"><MissedDeposits /></RequireAuth>} />
        <Route path='/upcomingdepositsplandetails/:planId' element={<RequireAuth type="auth"><UpcomingDepositsPlanDetails /></RequireAuth>} />
        <Route path='/jewellerstorelist' element={<RequireAuth type="auth"><JewellerStoreList /></RequireAuth>} />
        <Route path='/missedinstallmentdetail/:userId' element={<RequireAuth type="auth"><MissedInstallmentDetail /></RequireAuth>} />
        <Route path='/referrallist' element={<RequireAuth type="auth"><ReferralsList /></RequireAuth>} />
        <Route path='/referraljewellerstoredetails/:userId' element={<RequireAuth type="auth"><ReferralJewellerStoreDetails /></RequireAuth>} />
        <Route path='/upcommingmaturity' element={<RequireAuth type="auth"><UpcommingMaturity /></RequireAuth>} />
        <Route path='/maturedplans' element={<RequireAuth type="auth"><MaturedPlans /></RequireAuth>} />
        <Route path='/apointmrntlist' element={<RequireAuth type="auth"><ApointmrntList /></RequireAuth>} />
        <Route path='/apointmentdetail' element={<RequireAuth type="auth"><ApointmentDetail/></RequireAuth>} />
        <Route path='/profilevisitlist' element={<RequireAuth type="auth"><ProfileVisitList /></RequireAuth>} />
        <Route path='/referralsavingplanslist/:phoneNumber' element={<RequireAuth type="auth"><ReferralSavingPlanList /></RequireAuth>} />
        <Route path='/totalsavingplanslist' element={<RequireAuth type="auth"><TotalSavingPlans /></RequireAuth>} />
        <Route path='/savingplandetails/:planId/:name/:phoneNumber' element={<RequireAuth type="auth"><SavingPlanMoreDetails /></RequireAuth>} />
        <Route path='/profileviewdetails/:Id/:storeName/:contactNumber' element={<RequireAuth type="auth">< ProfileVisitDetail /></RequireAuth>} />
      </Routes>
      </UserAuthProvider>
          </main>
        </>
      )}
    
    </div>
    </ThemeProvider>
    </colorModeContext.Provider>
  );
}

export default App;
