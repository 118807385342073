import { Box, Typography, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../utils/authUtils";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const ProfileVisitDetail = () => {
  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //using this to fetch the userId and phone numbers from TotalUsers dataTable
  const { Id, storeName, contactNumber } = useParams();
  const [userWithdrawalDetails, setuserWithdrawalDetails] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //Prepping to fetch the API

  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  // const access_token = getAccessToken();

 
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    // Fetch user saving plans based on the userId/investor-id
    fetchUserSavingsPlan(Id);
  }, [Id]);

  //Fetching the Saving Plans List of a user using userId/investor-id
  const fetchUserSavingsPlan = async (Id) => {
    setLoading(true);
    const apiUrl = `${base_url}/api/v1/jewellerdashboard/store-views/${Id}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate("/");
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const dataArray = Array.isArray(data) ? data : [data];
      setuserWithdrawalDetails(dataArray); //only the list of saving plans
      //   console.log(dataArray); //all the data from the api
      
    } catch (error) {
      console.error("Error fetching user saving plans:", error);
    } finally {
      setLoading(false);
    }
  };



 


  

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "id",
      headerName: "Store ID",
      valueGetter: (params) => params.row.jeweller_store.id,
    },
    {
      field: "store_name",
      headerName: "Store Name",
      valueGetter: (params) => params.row.jeweller_store.store_name,
      minWidth: 150,
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      valueGetter: (params) => params.row.jeweller_store.contact_number,
      minWidth: 100,
    },
    {
      field: "address",
      headerName: "Store Address",
      valueGetter: (params) => params.row.jeweller_store.address,
      minWidth: 250,
    },
    {
      field: "location",
      headerName: "Store Location",
      valueGetter: (params) => params.row.jeweller_store.location,
      minWidth: 250,
    },
  ];

  return (
    <>
      <Box m="20px">
        {/* Header */}
        <Box>
          <Header title={`Store Details`} />
          <h3>Store Name: {storeName}</h3>
          <h4>Store Number: {contactNumber}</h4>
        </Box>
        {/* DataTable */}

        <Box m="40px 0 0 0" height="75vh">
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/profilevisitlist"
              >
                Profile Vist List
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"
              >
                {storeName}
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[400],
                color: `#fff`,
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[400],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rowHeight={60}
              rows={userWithdrawalDetails}
              columns={columns}
              loading={loading}
              hideFooterPagination={true}
              onCellClick={handleRowClick}
            />
          </Box>
          <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Store Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <div>
                  <table>
                    <tr>
                      <th>Store ID</th>
                      <td>{selectedRow.jeweller_store.id}</td>
                    </tr>
                    <tr>
                      <th>Store Name</th>
                      <td>{selectedRow.jeweller_store.store_name}</td>
                    </tr>
                    {/* <tr><th>Visit Count</th><td>{selectedRow.count}</td></tr> */}
                    <tr>
                      <th>Contact Number</th>
                      <td>{selectedRow.jeweller_store.contact_number}</td>
                    </tr>
                    <tr>
                      <th>Store Address</th>
                      <td>{selectedRow.jeweller_store.address}</td>
                    </tr>
                    <tr>
                      <th>Store Location</th>
                      <td>
                        <a
                          style={{ textDecoration: "none"}}
                          href={selectedRow.jeweller_store.location}
                        >
                          {selectedRow.jeweller_store.location}
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleCloseModal}
                color="warning"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </>
  );
};

export default ProfileVisitDetail;
