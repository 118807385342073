import { Box, Typography, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../utils/authUtils";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const UserWithdrawalDetails = () => {
  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //using this to fetch the userId and phone numbers from TotalUsers dataTable
  const { userId, phoneNumber, name } = useParams();
  const [userWithdrawalDetails, setuserWithdrawalDetails] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //Prepping to fetch the API

  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  // const access_token = getAccessToken();

  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchUserSavingsPlan(userId, page, searchInput);
  };

  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    // Fetch user saving plans based on the userId/investor-id
    fetchUserSavingsPlan(userId);
  }, [userId]);

  //Fetching the Saving Plans List of a user using userId/investor-id
  const fetchUserSavingsPlan = async (userId) => {
    setLoading(true);
    const apiUrl = `${base_url}/api/v1/jewellerdashboard/withdrawal/${userId}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate("/");
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const dataArray = Array.isArray(data) ? data : [data];
      setuserWithdrawalDetails(dataArray); //only the list of saving plans
      //   console.log(dataArray); //all the data from the api
      //   setTotalRows(data.count);
      //   setNextPage(data.next);
      //   setPreviousPage(data.previous);
    } catch (error) {
      console.error("Error fetching user saving plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => {
        const allRowIds = params.api ? params.api.getAllRowIds() : [];
        return allRowIds.indexOf(params.id) + 1;
      },
    },
    {
      field: "id",
      headerName: "ID",
      minWidth: 20,
    },
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params) => params.row.investor.name,
      minWidth: 200,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      valueGetter: (params) => params.row.investor.phone_number,
      minWidth: 100,
    },
    {
      field: "created_at",
      headerName: "Withdrawal Request Date",
      renderCell: (params) => {
        const timestamp = params.row.created_at;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
      minWidth: 150,
    },
    {
      field: "is_withdrawal_processed",
      headerName: "Withdrawal Processed",
      valueGetter: (params) =>
        params.row.is_withdrawal_processed ? "Yes" : "No",
      minWidth: 100,
    },
    {
      field: "withdrawal_processed_date",
      headerName: "Withdrawal Processed Date",
      renderCell: (params) => {
        const timestamp = params.row.withdrawal_processed_date;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
      minWidth: 100,
    },
    {
      field: "is_withdrawal_done",
      headerName: "Withdrawal Completed",
      valueGetter: (params) => (params.row.is_withdrawal_done ? "Yes" : "No"),
      minWidth: 100,
    },
    {
      field: "created_at",
      headerName: "Withdrawal Request Date",
      renderCell: (params) => {
        const timestamp = params.row.created_at;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },

      minWidth: 100,
    },
    {
      field: "withdrawal_date",
      headerName: "Withdrawal Date",
      renderCell: (params) => {
        const timestamp = params.row.created_at;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];
      },

      minWidth: 100,
    },
  ];

  return (
    <>
      <Box m="20px">
        {/* Header */}
        <Box>
          <Header title="Withdrawal Details" />
        </Box>
        {/* DataTable */}

        <Box m="40px 0 0 0" height="75vh">
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/withdrawal"
              >
                Withdrawal List
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"
              >
                Withdrawal Details
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[400],
                color: `#fff`,
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[400],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rowHeight={60}
              rows={userWithdrawalDetails}
              columns={columns}
              loading={loading}
              hideFooterPagination={true}
              onCellClick={handleRowClick}
            />
          </Box>
          <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Upcoming Installment- User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <div>
                  <table>
                    <tbody>
                      {columns.map((column) => (
                        <tr key={column.field}>
                          <th>{column.headerName}</th>
                          <td>
                            {column.renderCell
                              ? column.renderCell({ row: selectedRow })
                              : column.valueGetter
                              ? column.valueGetter({ row: selectedRow })
                              : selectedRow[column.field]}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleCloseModal}
                color="warning"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </>
  );
};

export default UserWithdrawalDetails;
