import { Box, Typography, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../utils/authUtils";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const SavingPlanMoreDetails = () => {
//for the dashboard theme
const theme = useTheme();
const colors = tokens(theme.palette.mode);

//using this to fetch the userId and phone numbers from TotalUsers dataTable
const {planId, name, phoneNumber } = useParams();
const [userSavingsPlan, setuserSavingsPlan] = useState([]);
const [searchInput, setSearchInput] = useState("");
const [page, setPage] = useState(1);
const [pageSize] = useState(25);
const [totalRows, setTotalRows] = useState(0);
const [loading, setLoading] = useState(false);
const [nextPage, setNextPage] = useState(null);
const [previousPage, setPreviousPage] = useState(null);
const [selectedRow, setSelectedRow] = useState(null);
const [isModalOpen, setIsModalOpen] = useState(false);
//Prepping to fetch the API

const base_url = "https://proapi.getplus.in";
const navigate = useNavigate();
// const access_token = getAccessToken();

const handleSearch = () => {
  // Make an API request to filter data based on the search input
  setPage(1);
  fetchUserSavingsPlan(page, searchInput);
};

const handleSearchInputChange = (e) => {
  setPage(1);
  setSearchInput(e.target.value);
};
// calling popup
const handleRowClick = (params) => {
setSelectedRow(params.row);
setIsModalOpen(true);
};

const handleCloseModal = () => {
setIsModalOpen(false);
};
useEffect(() => {
  // Fetch user saving plans based on the userId/investor-id
  fetchUserSavingsPlan(planId);
}, [planId]);

//Fetching the Saving Plans List of a user using userId/investor-id
const fetchUserSavingsPlan = async (planId) => {
  setLoading(true);
  const apiUrl = `${base_url}/api/v1/jewellerdashboard/savings-plan/${planId}`;
  try {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      // Handle the case where there is no access token
      console.error("Access token not found");
      navigate('/');
      return;
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(apiUrl, {
      method: "GET",
      headers,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    const dataArray = Array.isArray(data) ? data : [data];
    setuserSavingsPlan(dataArray); //only the list of saving plans
    // console.log(data); //all the data from the api
    setTotalRows(data.count);
    setNextPage(data.next);
    setPreviousPage(data.previous);
  } catch (error) {
    console.error("Error fetching user saving plans:", error);
  } finally {
    setLoading(false);
  }
};

const handleNextPage = () => {
  if (nextPage) {
    const pageNumber = new URL(nextPage).searchParams.get("page");
    setPage(parseInt(pageNumber));
  }
};

const handlePreviousPage = () => {
  if (previousPage) {
    let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
    const urlSearchParams = new URLSearchParams(previousPage);

    if (urlSearchParams.has("page")) {
      pageNumber = parseInt(urlSearchParams.get("page"));
    }

    setPage(pageNumber);
  }
};

const generatePageButtons = () => {
  const maxButtons = 10; // Maximum number of buttons to display
  const buttons = [];
  const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
  const endPage = Math.min(
    Math.ceil(totalRows / pageSize),
    startPage + maxButtons - 1
  );

  // Generate the "Previous Page" button
  buttons.push(
    <button
      style={{ padding: "5px", borderRadius: "5px" }}
      key="previous"
      onClick={handlePreviousPage}
      disabled={!previousPage}
    >
      Previous Page
    </button>
  );

  // Add the first page button
  if (startPage > 1) {
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key={1}
        onClick={() => handlePageClick(1)}
      >
        1
      </button>
    );
    if (startPage > 2) {
      // Add ellipsis if there are pages between the first page and startPage
      buttons.push(<span key="ellipsis-start">...</span>);
    }
  }

  // Generate page number buttons
  for (let i = startPage; i <= endPage; i++) {
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key={i}
        onClick={() => handlePageClick(i)}
        className={`page-button ${i === page ? "active" : ""}`}
      >
        {i}
      </button>
    );
  }

  // Add the last page button
  if (endPage < Math.ceil(totalRows / pageSize)) {
    if (endPage < Math.ceil(totalRows / pageSize) - 1) {
      // Add ellipsis if there are pages between endPage and the last page
      buttons.push(<span key="ellipsis-end">...</span>);
    }
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key={Math.ceil(totalRows / pageSize)}
        onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
      >
        {Math.ceil(totalRows / pageSize)}
      </button>
    );
  }

  // Generate the "Next Page" button
  buttons.push(
    <button
      style={{ padding: "5px", borderRadius: "5px" }}
      key="next"
      onClick={handleNextPage}
      disabled={!nextPage}
    >
      Next Page
    </button>
  );

  return buttons;
};

const handlePageChange = (pageNumber) => {
  setPage(pageNumber);
};

const handlePageClick = (pageNumber) => {
  setPage(pageNumber);
};

const pageNumbers = [];
for (let i = 1; i <= Math.ceil(totalRows / pageSize); i++) {
  pageNumbers.push(i);
}

const columns = [
  {
    field: "Sl_No",
    headerName: "Sl No",
    renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
    
  },
  {
    field: "plan_id",
    headerName: "Plan ID",
    minWidth: 250,
  },
  {
    field: "plan_name",
    headerName: "Plan Name",
    minWidth: 250,
  },
  {
    field: "plan_type",
    headerName: "Plan Type",
    minWidth: 250,
  },
    {
    field: "duration_of_saving_in_months",
    headerName: "Plan Duration",
    minWidth: 50,
  },
  {
    field: "amount",
    headerName: "Amount",
    minWidth: 100,
  },
  {
    field: "available_amount",
    headerName: "Available Amount",
    minWidth: 100,
  },
  {
    field: "plan_activated_on",
    headerName: "Plan Activation",
    renderCell: (params) => {
      const timestamp = params.row.plan_activated_on;
      if (timestamp === null) {
        return <div>No date</div>; // Handle null date
      }

      // Create a new Date object from the timestamp
      const dateObject = new Date(timestamp);

      // Get the date in YYYY-MM-DD format
      const date = dateObject.toISOString().split("T")[0];

      // Get the time in HH:MM:SS format
      const time = dateObject.toTimeString().split(" ")[0];

      return (
        <div>
          <div>Date: {date}</div>
          <div>Time: {time}</div>
        </div>
      );
    },
    minWidth: 150,
  },
  {
    field: "is_closed",
    headerName: "Plan Activation",
    valueGetter: (params) => params.row.is_closed? "Yes":"No",
    minWidth: 50,
  },
  {
    field: "maturity_date",
    headerName: "Maturity Date",
    renderCell: (params) => {
        const timestamp = params.row.maturity_date;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }
  
        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);
  
        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];
  
        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];
  
        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
    minWidth: 100,
  },
  {
    field: "closed_date",
    headerName: "Plan CLosed Date",
    renderCell: (params) => {
        const timestamp = params.row.closed_date;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }
  
        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);
  
        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];
  
        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];
  
        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
    minWidth: 100,
  },

];
  return (
    <>
      <Box m="20px">
        <Header
          title={`Saving Plans Details`}
        //   subtitle={`Phone Number: ${phoneNumber}`}
        />
        <h3>Name: {name} </h3>
        <h4>Pnone: {phoneNumber} </h4>

        <Box m="40px 0 0 0" height="65vh">
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/totalsavingplanslist"
              >
               Saving Plans List
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"
              >
                {name}
              </Link>
              
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[400],
                color: `#fff`,
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[400],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "& .MuiDataGrid-columnHeader": {
                  // Forced to use important since overriding inline styles
                  height: "unset !important",
                },
                "& .MuiDataGrid-columnHeaders": {
                  // Forced to use important since overriding inline styles
                  maxHeight: "168px !important",
                },
                "& .MuiDataGrid-cellContent": {
                  whiteSpace: "normal",
                  overflow: "visible",
                  wordWrap: "break-word",
                },
              }}
              rowHeight={75}
              rows={userSavingsPlan}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              getRowId={(row) => row.plan_id}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination = {true}
              onCellClick={handleRowClick}

            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
<DialogTitle>User Name:{name}</DialogTitle>
<DialogContent>
  {selectedRow && (
    <div>
      <table>
        <tr>
            <th>
                Plan ID
            </th>
            <td>
            {selectedRow.plan_id}
            </td>
        </tr>
        <tr>
            <th>
Plan Name
            </th>
            <td>
            {selectedRow.plan_name}
            </td>
        </tr>
        <tr>
            <th>
Plan Type
            </th>
            <td>
            {selectedRow.plan_type}
            </td>
        </tr>
        <tr>
            <th>
Plan Duration
            </th>
            <td>
            {selectedRow.duration_of_saving_in_months}
            </td>
        </tr>
        <tr>
            <th>
            Amount
            </th>
            <td>
            {selectedRow.amount}
            </td>
        </tr>
        <tr>
            <th>
            Available Amount
            </th>
            <td>
            {selectedRow.available_amount}
            </td>
        </tr>
        <tr>
            <th>
Plan Activation
            </th>
            <td>
            {selectedRow.plan_activated_on?selectedRow.plan_activated_on:"No Date"}
            </td>
        </tr>
        <tr>
            <th>
            Plan Closed
            </th>
            <td>
            {selectedRow.is_closed?"Yes":"No"}
            </td>
        </tr>
        <tr>
            <th>
            Maturity Date
            </th>
            <td>
            {selectedRow.maturity_date? selectedRow.maturity_date:"No Date"}
            </td>
        </tr>
        <tr>
            <th>
            Plan Closed Date
            </th>
            <td>
            {selectedRow.closed_date?selectedRow.closed_date:"No Date"}
            </td>
        </tr>
      </table>
    </div>
    
    
  )}
</DialogContent>
<DialogActions>
  <Button variant="contained" onClick={handleCloseModal} color="warning">
    Close
  </Button>
</DialogActions>
</Dialog>
        </Box>
      </Box>
    </>
  );
}

export default SavingPlanMoreDetails;