//WE CAN USE MUI DRAWER IF PRO SIDEBAR DOESNT GETS DEPRICIATED
import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import Dashboard from "../dashboard/Dashboard";
import 'react-pro-sidebar/dist/css/styles.css';

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined"; //users
import SipIcon from '@mui/icons-material/Sip'; //Sip
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'; //Sip
import UpcomingOutlinedIcon from '@mui/icons-material/UpcomingOutlined'; //Upcoming
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import { useLocation } from "react-router-dom";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const Item = ({title, to, icon, selected, setSelected}) => {
const theme = useTheme();
const colors = tokens(theme.palette.mode);
return (
    <MenuItem active={selected===title} style={{color: colors.grey[100]}} onClick={() => setSelected(title)} icon={icon}>
        <Typography>
            {title}
        </Typography>
        <Link to={to} />
    </MenuItem>
)

}

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [selected, setSelected] = useState('Dashboard');
    // const location = useLocation();
    // const isMenuItemActive = (path) => location.pathname === path;
    
    return (
        <Box
        sx={{
         "& .pro-sidebar-inner":{
             background: `${colors.primary[400]} !important`
         },
         "& .pro-icon-wrapper":{
             backgroundColor:"transparent !important"
         },
         "& .pro-inner-item":{
             padding:"5px 35px 5px 20px !important"
         },
         "& .pro-inner-item:hover":{
             color:"#868dfb !important"
         },
         "& .pro-menu-item.active":{
             color:"#6870fa !important"
         }
        }}
        >
            <div className="sidebar">
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    <MenuItem 
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                    style={{
                        margin:"10px 0 20px 0",
                        color: colors.grey[100],
                    }}
                    >
                         {!isCollapsed && (
                    <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    ml="15px"
                  >
                    <Typography variant="h4" color={colors.grey[100]}>
                      Customer Care
                    </Typography>
                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                      <MenuOutlinedIcon />
                    </IconButton>
                  </Box>    
                )}
                    </MenuItem>
                    {/* PLUS LOGO */}

                    {!isCollapsed && (
                <Box mb="25px">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <img 
                        alt='profile-user'
                        width="100px"
                        height="100px"
                        src={`../../assets/plusLogo.jfif`}
                        style={{cursor:"pointer", borderRadius:"50%"}}
                        />
                    </Box>

                    <Box textAlign="center">
                        <Typography variant='h3' color={colors.grey[100]} fontWeight="bold" sx={{m:"10px 0 0 0"}}>
                            PLUS
                        </Typography>
                        {/* <Typography variant='h5' color={colors.greenAccent[500]}>
                            VP Fancy Admin
                        </Typography> */}
                    </Box>
                </Box>
            )}

            {/* Menu Items */}
            <Box paddingLeft={isCollapsed ? undefined: '10%'}>
                <Item
                title={"Dashboard"}
                to={"/dashboard"}
                icon={<HomeOutlinedIcon/>}
                selected={selected}
                setSelected={setSelected}
                />
                <Typography
                variant='h6'
                color={colors.grey[300]}
                sx={{m: "15px 0 5px 20px"}}>
                    Users
                </Typography>
                <Item
                title={"Total Referred Users"}
                to={"/referrallist"}
                icon={<PeopleOutlinedIcon/>}
                selected={selected}
                setSelected={setSelected}
                />
                <Typography
                variant='h6'
                color={colors.grey[300]}
                sx={{m: "15px 0 5px 20px"}}>
                    SIPs & Deposits
                </Typography>
                <Item
                title={"Total Savings"}
                to={"/totalsavingplanslist"}
                icon={<SavingsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                />
                <Item
                title={"Upcoming Installments"}
                to={"/upcominginstallments"}
                
                icon={<PaidOutlinedIcon/>}
                selected={selected}
                setSelected={setSelected}
                />
                <Item
                title={"Missed Installments"}
                to={"/misseddeposits"}
                icon={<PaymentsOutlinedIcon/>}
                selected={selected}
                setSelected={setSelected}
                />
                <Item
                title={"Upcomin Maturity Plans"}
                to={"/upcommingmaturity"}
                icon={<ScheduleOutlinedIcon/>}
                selected={selected}
                setSelected={setSelected}
                />
                <Item
                title={"Matured Plans"}
                to={"/maturedplans"}
                icon={<DataSaverOnOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                />
                <Typography
                variant='h6'
                color={colors.grey[300]}
                sx={{m: "15px 0 5px 20px"}}>
                    Orders
                </Typography>
                <Item
                title={"Gold Coin Orders"}
                to={"/goldcoinorders"}
                icon={<ListAltIcon/>}
                selected={selected}
                setSelected={setSelected}
                />
                <Typography
                variant='h6'
                color={colors.grey[300]}
                sx={{m: "15px 0 5px 20px"}}>
                    Withdrawals
                </Typography>
                <Item
                title={"withdrawals"}
                to={"/withdrawal"}
                icon={<AccountBalanceWalletOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                />
                <Typography
                variant='h6'
                color={colors.grey[300]}
                sx={{m: "15px 0 5px 20px"}}>
                    Appointment and store details
                </Typography>
                <Item
                title={"Appointmnets"}
                to={"/apointmrntlist"}
                icon={<BookOnlineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                />
                <Item
                title={"Stores"}
                to={"/jewellerstorelist"}
                icon={<StorefrontOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                />
                <Item
                title={"Store Visits"}
                to={"/profilevisitlist"}
                icon={<VisibilityOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                />
            </Box>
                </Menu>

            </ProSidebar>
            </div>
        </Box>
    )
}

export default Sidebar;


