import {
  Box,
  Button,
  IconButton,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, colorModeContext, tokens } from "../../theme";
import { InputBase } from "@mui/material";
import whatsappLogo from "../../assets/whatsapp.png";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import authApi from "../../api";

const clearAccessToken = () => {
  localStorage.removeItem("auth");
};

const handleLogout = () => {
  clearAccessToken();
  window.location.href = "/";
};

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(colorModeContext);
  const [jewellerProfile, setJewellerProfile] = useState([]);

  // console.log(jewellerProfile, "jewellerProfile data");
  const fetchJewellerProfile = async () => {
    try {
      const data = await authApi.JewellerProfile();
      setJewellerProfile(data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchJewellerProfile();
  }, []);

  const getWhatsAppText = () => {
    if (jewellerProfile && jewellerProfile.manager_type === "BRAND MANAGER") {
      return `Hi, I am the Brand Manager of ${jewellerProfile.brand_name}`;
    } else if (
      jewellerProfile &&
      jewellerProfile.manager_type === "STORE MANAGER"
    ) {
      return `Hi, I am the Store Manager of ${jewellerProfile.store_name}`;
    } else {
      return "Hi, I would like to chat with you.";
    }
  };

  return (
    // borderBottom="2px solid"
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      {/* < Box display="flex"
              backgroundColor={colors.primary[400]}
              borderRadius="3px"

              >
                <InputBase sx={{ml:2, flex: 1}} placeholder="Search" />
                <IconButton type="button" sx={{p: 1}}>
                <SearchIcon />
                </IconButton>
              </Box> */}

      <Box display="flex" ml="2" flex="2" p="1">
        <Typography style={{fontWeight:"600"}} color={colors.greenAccent[200]} variant="h1">
          Jeweller Dashboard
        </Typography>
      </Box>

      {/* ICONS */}

      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <div className="whatsapp-button" style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
          <a
            href={`https://api.whatsapp.com/send/?phone=9035202567&text=${encodeURIComponent(
              getWhatsAppText()
            )}&type=phone_number&app_absent=0`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsappLogo} alt="WhatsApp Logo" />
            WhatsApp
          </a>
        </div>
        <IconButton>
          {/* <PersonOutlinedIcon /> */}
        </IconButton>
        <Button variant="contained" color="warning" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
