import React, { useEffect, useState } from "react";
import api from '../api';

const AuthContext = React.createContext(null);

const UserAuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [account, setAccount] = useState(null);
  const [user, setUser] = useState(null);

  const refreshAuth = async () => {
    const authToken = await localStorage.getItem("auth");
    const storage = authToken ? JSON.parse(authToken) : null;

    if (storage && storage?.token) {
      const { token } = storage;
    //   try {
    //     const { user } = await authApi.user();
    //     if (user) {
    //       setUser(user);
    //       setAccount(user.account);
    //     }
        setToken(token);
        // console.log(token,"bhvghdtrsre");
    //   } catch {
    //     handleLogout();
    //   }
    }
  };
  useEffect(() => {
    refreshAuth();
  }, []);

  const handleLogin = async (accessToken) => {
    const token = accessToken;
    await localStorage.setItem("auth", JSON.stringify({ token }));
    setToken(token);
    setAccount(null);
    setUser(null);
  };

  const handleLogout = async () => {
    await localStorage.removeItem("auth");
    setToken(null);
  };

  const value = {
    token,
    user: user,
    account,
    refreshAuth,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useUserAuth = () => {
  return React.useContext(AuthContext);
};

export { UserAuthProvider, useUserAuth };